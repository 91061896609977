import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight-semi-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.tableHeaders,
    items: _ctx.tableItems,
    "hide-footer": "",
    "items-per-page": 12,
    class: "mt-4"
  }, {
    "body-cell-data-slot": _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.formatDate(row.data, 'D MMMM yyyy')), 1)
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}