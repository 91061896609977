import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e9e4ce5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "col-grow" }
const _hoisted_3 = { class: "row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_reporting_messages_calls_selector_year = _resolveComponent("reporting-messages-calls-selector-year")!
  const _component_reporting_messages_calls_selector_month = _resolveComponent("reporting-messages-calls-selector-month")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.accounts,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accounts) = $event)),
        type: "selectSearchCategory",
        class: "search-category",
        size: "small"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tm_dropdown_download, {
        "icon-only": _ctx.isXsMax,
        size: "large"
      }, null, 8, ["icon-only"]),
      (_ctx.period === 'year')
        ? (_openBlock(), _createBlock(_component_reporting_messages_calls_selector_year, {
            key: 0,
            year: _ctx.year,
            years: _ctx.years,
            "base-url-name": `base.reporting.${_ctx.page}.year`,
            "back-url-name": `base.reporting.${_ctx.page}.full`,
            class: "ml-2"
          }, null, 8, ["year", "years", "base-url-name", "back-url-name"]))
        : (_openBlock(), _createBlock(_component_reporting_messages_calls_selector_month, {
            key: 1,
            class: "ml-2",
            "base-url-name": `base.reporting.${_ctx.page}.month`,
            "back-url-name": `base.reporting.${_ctx.page}.year`
          }, null, 8, ["base-url-name", "back-url-name"]))
    ])
  ]))
}