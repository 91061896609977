import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "self-center ml-3 subhead-semi-bold-16 ls-reduce-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      size: "large",
      "icon-only": "",
      to: _ctx.leftTo,
      disable: !_ctx.leftTo
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, { name: "chevron_left" })
      ]),
      _: 1
    }, 8, ["to", "disable"]),
    _createVNode(_component_tm_button, {
      size: "large",
      "icon-only": "",
      class: "ml-2",
      to: _ctx.rightTo,
      disable: !_ctx.rightTo
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, { name: "chevron_right" })
      ]),
      _: 1
    }, 8, ["to", "disable"]),
    _createElementVNode("div", _hoisted_2, " Year " + _toDisplayString(_ctx.year), 1),
    _createVNode(_component_tm_button, {
      "icon-only": "",
      size: "xSmall",
      class: "ml-1",
      to: { name: _ctx.backUrlName }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, { name: "cancel" })
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}