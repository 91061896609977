
import type { PropType } from 'vue'
import {
  defineComponent, toRefs, computed
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
    years: {
      type: Array as PropType<number[]>,
      required: true,
    },
    baseUrlName: {
      type: String,
      required: true,
    },
    backUrlName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { year, years, baseUrlName } = toRefs(props)

    const index = computed(() => years.value.findIndex((y) => y === year.value))

    const leftTo = computed(() => {
      if (!index.value) return null
      return {
        name: baseUrlName.value,
        params: {
          year: years.value[index.value - 1],
        },
      }
    })

    const rightTo = computed(() => {
      if (years.value.length - 1 === index.value) return null
      return {
        name: baseUrlName.value,
        params: {
          year: years.value[index.value + 1],
        },
      }
    })

    return {
      leftTo,
      rightTo,
      index,
    }
  },
})
