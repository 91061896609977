import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_messages_calls_table_filter_year_month = _resolveComponent("reporting-messages-calls-table-filter-year-month")!
  const _component_reporting_messages_table_month = _resolveComponent("reporting-messages-table-month")!
  const _component_reporting_messages_stats = _resolveComponent("reporting-messages-stats")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_reporting_messages_calls_table_filter_year_month, {
      period: "month",
      page: "messages"
    }),
    _createVNode(_component_reporting_messages_table_month),
    _createVNode(_component_reporting_messages_stats, { type: "days" })
  ]))
}