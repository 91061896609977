import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, {
      modelValue: _ctx.account,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.account) = $event)),
      options: _ctx.accountOptions,
      clearable: false,
      type: "select",
      size: "small"
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_tm_dropdown_download)
  ]))
}