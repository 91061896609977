
import { defineComponent, ref } from 'vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'

export default defineComponent({
  components: {
    TmDropdownDownload,
  },
  setup() {
    const accountOptions = ref([
      'Sent SMS',
      'Received SMS',
      'Delivery rate',
      'Reply rate',
      'Costs',
    ])
    const account = ref('Sent SMS')

    return {
      accountOptions,
      account,
    }
  },
})
