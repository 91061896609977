
import { defineComponent } from 'vue'
import ReportingMessagesStats from '@/components/pages/reporting/messagesCalls/messages/ReportingMessagesStats.vue'
import ReportingMessagesTableMonth from '@/components/pages/reporting/messagesCalls/messages/ReportingMessagesTableMonth.vue'
import ReportingMessagesCallsTableFilterYearMonth from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsTableFilterYearMonth.vue'

export default defineComponent({
  components: {
    ReportingMessagesCallsTableFilterYearMonth,
    ReportingMessagesTableMonth,
    ReportingMessagesStats,
  },
})
