
import type { PropType } from 'vue'
import {
  defineComponent, toRefs, computed
} from 'vue'
import ReportingMessagesStatsFilter from '@/components/pages/reporting/messagesCalls/messages/ReportingMessagesStatsFilter.vue'
import ReportingMessagesCallsStatsChartFull from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsStatsChartFull.vue'
import ReportingMessagesCallsStatsChartYear from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsStatsChartYear.vue'
import ReportingMessagesCallsStatsChartMonth from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsStatsChartMonth.vue'

export default defineComponent({
  components: {
    ReportingMessagesStatsFilter,
    ReportingMessagesCallsStatsChartFull,
    ReportingMessagesCallsStatsChartYear,
    ReportingMessagesCallsStatsChartMonth,
  },
  props: {
    type: {
      type: String as PropType<'year' | 'month' | 'days'>,
      required: true,
    },
  },
  setup(props) {
    const { type } = toRefs(props)

    const statsChartMap = {
      year: 'ReportingMessagesCallsStatsChartFull',
      month: 'ReportingMessagesCallsStatsChartYear',
      days: 'ReportingMessagesCallsStatsChartMonth',
    }

    const componentName = computed(() => statsChartMap[type.value])
    return {
      componentName,
    }
  },
})
