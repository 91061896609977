
import { computed, defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useRoute } from 'vue-router'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    baseUrlName: {
      type: String,
      required: true,
    },
    backUrlName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute()
    const year = computed(() => route.params.year)
    const month = computed(() => route.params.month as string)

    const leftTo = computed(() => {
      if (month.value === '01') return null
      const prevMonth = parseInt(month.value, 10) - 1
      return {
        name: props.baseUrlName,
        params: {
          year: year.value,
          month: prevMonth < 10 ? `0${prevMonth}` : prevMonth,
        },
      }
    })

    const rightTo = computed(() => {
      if (month.value === '12') return null
      const nextMonth = parseInt(month.value, 10) + 1
      return {
        name: props.baseUrlName,
        params: {
          year: year.value,
          month: nextMonth < 10 ? `0${nextMonth}` : nextMonth,
        },
      }
    })

    return {
      formatDate,
      year,
      month,
      leftTo,
      rightTo,
    }
  },
})
