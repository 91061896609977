
import { defineComponent, ref, computed } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useRoute } from 'vue-router'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmTable,
  },
  setup() {
    const route = useRoute()
    const year = computed(() => route.params.year)
    const month = computed(() => route.params.month)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Day', value: 'data-slot' },
      { text: 'Sent', value: 'sent' },
      { text: 'Received', value: 'received' },
      { text: 'Delivery rate', value: 'deliveryRate' },
      { text: 'Reply rate', value: 'replyRate' },
      { text: 'Costs', value: 'costs' },
    ])

    const tableItems = computed(() => [
      { data: `${year.value}-${month.value}-01`, sent: '1,445', received: '45', deliveryRate: '51.91%', replyRate: '1.62%', costs: '$ 993.00' },
      { data: `${year.value}-${month.value}-03`, sent: '2,491', received: '91', deliveryRate: '78.57%', replyRate: '8.13%', costs: '$ 138.00' },
      { data: `${year.value}-${month.value}-05`, sent: '1,223', received: '23', deliveryRate: '45.94%', replyRate: '2.52%', costs: '$ 441.00' },
      { data: `${year.value}-${month.value}-07`, sent: '1,445', received: '99', deliveryRate: '52.22%', replyRate: '7.37%', costs: '$ 451.00' },
      { data: `${year.value}-${month.value}-09`, sent: '999', received: '39', deliveryRate: '24.84%', replyRate: '9.01%', costs: '$ 483.00' },
      { data: `${year.value}-${month.value}-11`, sent: '639', received: '26', deliveryRate: '94.08%', replyRate: '4.76%', costs: '$ 180.00' },
      { data: `${year.value}-${month.value}-13`, sent: '826', received: '312', deliveryRate: '98.39%', replyRate: '6.42%', costs: '$ 1,670.00' },
      { data: `${year.value}-${month.value}-15`, sent: '6,316', received: '93', deliveryRate: '55.68%', replyRate: '6.37%', costs: '$ 448.00' },
      { data: `${year.value}-${month.value}-17`, sent: '893', received: '31', deliveryRate: '64.19%', replyRate: '2.97%', costs: '$ 397.00' },
      { data: `${year.value}-${month.value}-19`, sent: '757', received: '57', deliveryRate: '11.57%', replyRate: '6.55%', costs: '$ 217.00' },
      { data: `${year.value}-${month.value}-21`, sent: '1,445', received: '99', deliveryRate: '52.22%', replyRate: '7.37%', costs: '$ 451.00' },
      { data: `${year.value}-${month.value}-23`, sent: '6,316', received: '93', deliveryRate: '55.68%', replyRate: '6.37%', costs: '$ 448.00' },
    ])

    return {
      formatDate,
      tableHeaders,
      tableItems,
    }
  },
})
