
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import ReportingMessagesCallsSelectorYear from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsSelectorYear.vue'
import ReportingMessagesCallsSelectorMonth from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsSelectorMonth.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  components: {
    ReportingMessagesCallsSelectorYear,
    ReportingMessagesCallsSelectorMonth,
    TmDropdownDownload,
  },
  props: {
    year: {
      type: Number,
    },
    years: {
      type: Array as PropType<number[]>,
    },
    period: {
      type: String as PropType<'year' | 'month'>,
      required: true,
    },
    page: {
      type: String as PropType<'messages' | 'calls'>,
      required: true,
    },
  },
  setup() {
    const { isXsMax } = useBreakpoints()

    const accounts = ref(reportingCategories[0])

    return {
      accounts,
      isXsMax,
    }
  },
})
